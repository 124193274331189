const DSLMentionsEN = {
  title: "Legal Notice",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Legal notice for the ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. COMPANY",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'} is published by ${process.env.REACT_APP_COMPANY_NAME || 'GZReactives'}, located at ${process.env.REACT_APP_COMPANY_ADDRESS || 'COMPANY\'s Address'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "2. Intellectual Property",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `All elements of the application, including texts, logos, and icons, are protected by intellectual property laws and are the exclusive property of ${process.env.REACT_APP_COMPANY_NAME || 'GZReactives'}, unless otherwise indicated. The icons in this application come from '@expo/vector-icons' and Flaticon (https://www.flaticon.com), which group open-source icon libraries. The application logo was generated using OpenAI's ChatGPT and DALL-E models.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "3. Font License",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The application uses the 'Quicksand' font, which is licensed under the SIL Open Font License, Version 1.1. You can view the details of this license at https://scripts.sil.org/OFL.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "4. Liability",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_COMPANY_NAME || 'GZReactives'} cannot be held liable for direct or indirect damages resulting from the use of the application. The application is provided 'as is' without any warranty of any kind.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "5. Contact",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `For any questions regarding this legal notice, please contact ${process.env.REACT_APP_COMPANY_NAME || 'GZReactives'} at the following email address: ${process.env.REACT_APP_CONTACT_EMAIL || 'Contact Email'}.`
            }
          ]
        }
      ]
    }
  ]
};

export default DSLMentionsEN;