import * as FiIcons from "react-icons/fi";
import { VscVscodeInsiders } from "react-icons/vsc";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest,
  FaTiktok,
  FaSnapchat,
  FaWhatsapp,
  FaDribbble,
  FaBehance,
  FaReddit,
  FaMedium,
  FaVimeo,
  FaTumblr,
  FaSoundcloud,
  FaSpotify,
  FaTwitch,
  FaDiscord,
  FaPlay,
  FaGithub,
  FaHtml5,
  FaCss3,
  FaSass,
  FaReact,
  FaBootstrap,
  FaNodeJs,
  FaGit,
  FaPhp,
  FaPython
} from "react-icons/fa";
import {
  SiTypescript,
  SiMongodb,
  SiFirebase,
  SiPostman,
  SiMysql
} from "react-icons/si";

export const iconMapping = {
  facebook: { icon: FaFacebook, color: "#fff" },
  twitter: { icon: FaTwitter, color: "#fff" },
  linkedin: { icon: FiIcons.FiLinkedin, color: "#fff" },
  instagram: { icon: FaInstagram, color: "#fff" },
  youtube: { icon: FaYoutube, color: "#fff" },
  pinterest: { icon: FaPinterest, color: "#fff" },
  tiktok: { icon: FaTiktok, color: "#fff" },
  snapchat: { icon: FaSnapchat, color: "#fff" },
  whatsapp: { icon: FaWhatsapp, color: "#fff" },
  github: { icon: FiIcons.FiGithub, color: "#fff" },
  dribbble: { icon: FaDribbble, color: "#fff" },
  behance: { icon: FaBehance, color: "#fff" },
  reddit: { icon: FaReddit, color: "#fff" },
  medium: { icon: FaMedium, color: "#fff" },
  vimeo: { icon: FaVimeo, color: "#fff" },
  tumblr: { icon: FaTumblr, color: "#fff" },
  soundcloud: { icon: FaSoundcloud, color: "#fff" },
  spotify: { icon: FaSpotify, color: "#fff" },
  twitch: { icon: FaTwitch, color: "#fff" },
  discord: { icon: FaDiscord, color: "#fff" },
  website: { icon: FiIcons.FiExternalLink, color: "#fff" },
  demo: { icon: FaPlay, color: "#fff" },
  TypeScript: { icon: SiTypescript, color: "#007ACC" },
  HTML: { icon: FaHtml5, color: "#E44D26" },
  CSS: { icon: FaCss3, color: "#33A9DC" },
  SASS: { icon: FaSass, color: "#CB6699" },
  React: { icon: FaReact, color: "#61DAFB" },
  ReactNative: { icon: FaReact, color: "#61DAFB" },
  Bootstrap: { icon: FaBootstrap, color: "#8512FB" },
  MongoDB: { icon: SiMongodb, color: "#439934" },
  Git: { icon: FaGit, color: "#F34F29" },
  Github: { icon: FaGithub, color: "#fff" },
  VSCode: { icon: VscVscodeInsiders, color: "#22A4F1" },
  Figma: { icon: FiIcons.FiFigma, color: "#FF7262" },
  Firebase: { icon: SiFirebase, color: "#FFA000" },
  Postman: { icon: SiPostman, color: "#F37036" },
  MySQL: { icon: SiMysql, color: "#00618A" },
  php: { icon: FaPhp, color: "#007ACC" },
  python: { icon: FaPython, color: "#FFDD53" },
  NodeJs: { icon: FaNodeJs, color: "#59A946" },
};
