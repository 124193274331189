
import emailjs from "emailjs-com";

const sendMail = async ({
  errorMessage,
  setSend,
  setLoading,
  setErrors,
  formValues,
  setFormValues }
) => {
  const mail = formValues.mail;
  const subject = formValues.subject;
  const message = formValues.message;
  const reply_to = mail;
  const serviceId = process.env.REACT_APP_MAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_MAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_MAILJS_USER_ID;

  try {
    const response = await emailjs.send(
      serviceId,
      templateId,
      { reply_to, mail, subject, message },
      userId
    );
    if (response.status === 200) {
      console.info("Successfully sent message.");
      setSend(true);
      setFormValues({ mail: "", subject: "", message: "" });
    }
  } catch (error) {
    console.error("error", error);

    setErrors((prev) => ({ ...prev, submit: errorMessage }));
  } finally {
    setLoading(false);

  }
};

export default sendMail