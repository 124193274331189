import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { gsap } from "gsap"
import './index.scss';
const Resume = ({ datas, pdfUrl, pngUrl, altImage }) => {
  const ctaResume = datas && datas.attributes.resumeCtaText.text;

  const pdf = `${pdfUrl}`
  const png = `${pngUrl}`

  const handleDownload = () => {
    saveAs(pdf, 'Giovanni_Zoppis_Resume.pdf');
  };

  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo('img', { opacity: 0 }, { opacity: 1, duration: 1 })
  }, [])
  return (
    <main className='resume-container'>
      <img
        src={png}
        className='shadow'
        style={{ width: '100%', borderRadius: '8px' }}
        alt={altImage}
      />
      <p className='text-policies'>
        {datas.attributes.policiesAccepted.text[0]}{' '}
        <Link className='legal-link' to='/policy'>
          {datas.attributes.policiesAccepted.text[1]}
        </Link>
        {datas.attributes.policiesAccepted.text[2]}
      </p>
      <button onClick={handleDownload} className='resume-button'>
        <p>{ctaResume}</p>
      </button>
    </main>
  )
}

export default memo(Resume); 
