const legalMentions = {
  title: "Legal Notice",
  blocks: [
    {
      type: "section",
      title: "1. Site Publisher",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `The website ${process.env.REACT_APP_WEBSITE_URL || 'Website Name'} is published by:` },
            { type: "text", text: `Name: ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}` },
            { type: "text", text: `Status: ${process.env.REACT_APP_COMPANY_STATUS || 'Sole Proprietorship'}` },
            { type: "text", text: `Address: ${process.env.REACT_APP_COMPANY_ADDRESS || 'Your professional or personal address'}` },
            { type: "text", text: `SIRET: ${process.env.REACT_APP_COMPANY_SIRET || 'SIRET number'}` },
            { type: "text", text: `Contact Email: ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@yoursite.com'}` }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "2. Website Hosting",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `The website is hosted by:` },
            { type: "text", text: `Hosting Provider: ${process.env.REACT_APP_HOSTING_PROVIDER || 'Vercel Inc.'}` },
            { type: "text", text: `Address: ${process.env.REACT_APP_HOSTING_ADDRESS || '340 S Lemon Ave #4133, Walnut, CA 91789'}` },
            { type: "text", text: `Website: ${process.env.REACT_APP_HOSTING_WEBSITE || 'https://vercel.com'}` },
            { type: "text", text: `Contact: ${process.env.REACT_APP_HOSTING_CONTACT || 'support@vercel.com'}` }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "3. Intellectual Property",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The content of the website ${process.env.REACT_APP_WEBSITE_URL || 'https://gzreactives.vercel.app '} (texts, images, videos, etc.) is the exclusive property of GZReactives, unless otherwise stated. Any reproduction, distribution, or use of this content without prior authorization is prohibited.`
            },
            {
              type: "text",
              text: "- Social media icons: The social media icons used on this site come from the SVG (Scalable Vector Graphics) library of the W3C (http://www.w3.org/2000/svg) and are under appropriate licensing."
            },
            {
              type: "text",
              text: "- Images and CV: The images and the content of my CV present on this site are my exclusive property."
            },
            {
              type: "text",
              text: "- Logo: The logo used on this site was generated with the help of the OpenAI tool and is subject to OpenAI’s terms of use."
            },
            {
              type: "text",
              text: "Any unauthorized use of elements from this site is strictly prohibited, subject to rights granted by their respective licenses."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "4. Liability",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `The publisher cannot be held responsible for any direct or indirect damage resulting from access or use of the site.` }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "5. Personal Data Protection",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `No personal data is collected without your consent. The website does not use cookies.` }
          ]
        }
      ]
    }
  ]
};

export default legalMentions;