const legalMentions = {
  title: "Mentions Légales",
  blocks: [
    {
      type: "section",
      title: "1. Éditeur du site",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `Le site ${process.env.REACT_APP_WEBSITE_URL || 'Nom du site'} est édité par :` },
            { type: "text", text: `Nom : ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l’entreprise'}` },
            { type: "text", text: `Statut : ${process.env.REACT_APP_COMPANY_STATUS || 'Micro-entrepreneur'}` },
            { type: "text", text: `Adresse : ${process.env.REACT_APP_COMPANY_ADDRESS || 'Ton adresse professionnelle ou personnelle'}` },
            { type: "text", text: `SIRET : ${process.env.REACT_APP_COMPANY_SIRET || 'Numéro de SIRET'}` },
            { type: "text", text: `Email de contact : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@tonsite.com'}` }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "2. Hébergement du site",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `Le site est hébergé par :` },
            { type: "text", text: `Hébergeur : ${process.env.REACT_APP_HOSTING_PROVIDER || 'Vercel Inc.'}` },
            { type: "text", text: `Adresse : ${process.env.REACT_APP_HOSTING_ADDRESS || '340 S Lemon Ave #4133, Walnut, CA 91789'}` },
            { type: "text", text: `Site Web : ${process.env.REACT_APP_HOSTING_WEBSITE || 'https://vercel.com'}` },
            { type: "text", text: `Contact : ${process.env.REACT_APP_HOSTING_CONTACT || 'support@vercel.com'}` }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "3. Propriété Intellectuelle",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Le contenu du site ${process.env.REACT_APP_WEBSITE_URL || 'https://gzreactives.vercel.app '} (textes, images, vidéos, etc.) est la propriété exclusive de GZReactives, sauf indication contraire. Toute reproduction, distribution ou utilisation de ce contenu sans autorisation préalable est interdite.`
            },
            {
              type: "text",
              text: "- Icônes des réseaux sociaux : Les icônes des réseaux sociaux utilisées sur ce site proviennent de la bibliothèque SVG (Scalable Vector Graphics) du W3C (http://www.w3.org/2000/svg) et sont sous licence appropriée."
            },
            {
              type: "text",
              text: "- Images et CV : Les images et le contenu de mon CV présents sur ce site sont ma propriété exclusive."
            },
            {
              type: "text",
              text: "- Logo : Le logo utilisé sur ce site a été généré avec l’aide de l’outil OpenAI et est soumis aux termes d’utilisation d’OpenAI."
            },
            {
              type: "text",
              text: "Toute utilisation non autorisée des éléments de ce site est strictement interdite, sous réserve de droits accordés par leurs licences respectives."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "4. Responsabilité",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `L'éditeur ne peut être tenu responsable des dommages directs ou indirects résultant de l'accès ou de l'utilisation du site.` }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "5. Protection des données personnelles",
      content: [
        {
          type: "paragraph",
          children: [
            { type: "text", text: `Aucune donnée personnelle n'est collectée sans votre consentement. Le site n'utilise pas de cookies.` }
          ]
        }
      ]
    }
  ]
};

export default legalMentions;