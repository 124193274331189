import { useDatasContext } from '../../../context/DatasProvider.js';
import CanvasThree from '../../../components/CanvasThree.jsx';
import './index.scss';
import { memo } from 'react';
import Footer from '../../../components/Footer/index.jsx';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const PrivacyPolicies = () => {
    const { datas, isLoading, error } = useDatasContext();


    if (!datas?.privacyPolicy) return <CanvasThree
        location={'/'}
        loading={isLoading.privacyPolicy ?? true}
        error={error.privacyPolicy ?? undefined}
    />;

    return (
        <>
            <main className='privacy-container'>
                <h1 className='privacy-title'>{datas.privacyPolicy.title}</h1>
                {datas.privacyPolicy.blocks.map((section, indexBlock) => (
                    section.type === 'introduction' ? (
                        <div key={indexBlock}>
                            <h2 className='section-title'>{section.title}</h2>
                            <div className='section-text'>
                                <BlocksRenderer
                                    key={`blocks-${indexBlock}`}
                                    blocks={{
                                        paragraph: ({ children, indexParagraph }) => (
                                            <div key={indexParagraph} style={{
                                                width: '100%',
                                                marginBottom: '4rem'
                                            }}>
                                                {children.map((child, indexChild) => (<p key={`${indexBlock}-${indexParagraph}-${indexChild}`}>{child}</p>))}
                                            </div>)
                                    }}
                                    content={section.content}
                                />
                            </div>
                        </div>
                    ) : section.type === 'section' ? (
                        <div key={indexBlock}>
                            <h2 className='section-title'>{section.attributes.title}</h2>
                            <div className='section-text'>
                                <BlocksRenderer
                                    key={`blocks-${indexBlock}`}
                                    blocks={{
                                        paragraph: ({ children, indexParagraph }) => (
                                            <div key={indexParagraph} style={{
                                                width: '100%',
                                                marginBottom: '4rem'
                                            }}>
                                                {children.map((child, indexChild) => (<p key={`${indexBlock}-${indexParagraph}-${indexChild}`}>{child}</p>))}
                                            </div>)
                                    }}
                                    content={section.attributes.content}
                                />
                            </div>
                        </div>
                    ) : null
                ))}
            </main>
            {isLoading.privacyPolicy || error.privacyPolicy ? null : <Footer />}
        </>
    );
};


export default memo(PrivacyPolicies);
