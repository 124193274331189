import { useState } from 'react';
import validateForm from '../../utils/validateForm';
import sendMail from '../../utils/sendMail';
import Spinner from '../../components/Spinner';
import './index.scss';

const ContactForm = ({ datas }) => {
  const [formValues, setFormValues] = useState({
    mail: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formErrors = validateForm(isChecked, formValues, datas);
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
        setLoading(false);
        return;
      }
      if (!isSent) {
        await sendMail({
          errorMessage: datas?.attributes?.messageErrorSubmit?.text,
          setSend: setIsSent,
          setLoading: setLoading,
          setErrors: setErrors,
          setFormValues: setFormValues,
          formValues,
        });
      }
    } catch (error) {
      console.error(error);
      setErrors((prev) => ({
        ...prev,
        submit: datas?.attributes?.messageErrorSubmit?.text,
      }));
    } finally {
      setLoading(false);
    }
  };

  if (!datas) return null;

  const {
    labelMail,
    labelSubject,
    labelMessage,
    messageCtaText,
    messageSent,
    labelCheck,
    mailPlaceholder,
    subjectPlaceholder,
    messagePlaceholder,
  } = datas.attributes;

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="email">{labelMail.text}</label>
          <input
            placeholder={mailPlaceholder.text}
            id="email"
            name="mail"
            value={formValues.mail}
            onChange={handleInputChange}
          />

          {errors.mail && (
            <div className="error-container">
              <p className="error">{errors.mail}</p>
            </div>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="subject">{labelSubject.text}</label>
          <input
            placeholder={subjectPlaceholder.text}
            id="subject"
            name="subject"
            value={formValues.subject}
            onChange={handleInputChange}
          />

          {errors.subject && (
            <div className="error-container">
              <p className="error">{errors.subject}</p>
            </div>
          )}
        </div>

        <div className="input-group">
          <label htmlFor="message">{labelMessage.text}</label>
          <textarea
            placeholder={messagePlaceholder.text}
            id="message"
            name="message"
            value={formValues.message}
            onChange={handleInputChange}
          />
          {errors.message && (
            <div className="error-container">
              <p className="error">{errors.message}</p>
            </div>
          )}
        </div>

        <section className="main-checkbox-container">
          <div className="label-check-container">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              checked={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
            />
            <div className="label-container">
              <label  >
                {labelCheck.text[0]}{' '}
                <a href="/policy" target="_blank" rel="noopener noreferrer">
                  {labelCheck.text[1]}
                </a>
              </label>
            </div>
          </div>
          {errors.terms && (
            <div className="error-container">
              <p className="error">{errors.terms}</p>
            </div>
          )}
        </section>

        {loading ? (
          <button disabled className="loader-button">
            <Spinner />
          </button>
        ) : isSent ? (
          <span className="send-message">
            <button disabled className="submit-button">
              <p>{messageCtaText.text}</p>
            </button>
            <p>{messageSent.text}</p>
          </span>
        ) : (
          <button type="submit" className="submit-button">
            <p>{messageCtaText.text}</p>
          </button>
        )}
        {errors.submit && (
          <div className="error-container">
            <p className="error">{errors.submit}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;

