
export default
  function validationForm(isChecked, inputs, datas) {
  const requiredMail = datas?.attributes?.requiredMail?.text
  const invalidMail = datas?.attributes?.invalidMail?.text
  const requiredSubject = datas?.attributes?.requiredSubject?.text
  const requiredMessage = datas?.attributes?.requiredMessage?.text
  const errors = {};
  if (!isChecked) {
    errors.terms = datas?.attributes?.invalidCheck?.text
  }
  if (!inputs.mail) {
    errors.mail = requiredMail;
  } else if (!/\S+@\S+\.\S+/.test(inputs.mail)) {
    errors.mail = invalidMail;
  }

  if (!inputs.subject) {
    errors.subject = requiredSubject;
  }
  if (!inputs.message) {
    errors.message = requiredMessage;
  }
  return errors;

}

