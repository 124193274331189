import { memo, useEffect } from 'react'
import { gsap } from 'gsap';
import './index.scss'

const Title = ({ datas }) => {
  const title = datas?.MainTitle;
  const subtitle = datas?.subtitle;

  useEffect(() => {
    gsap.fromTo(".subtitle", {
      opacity: 0,
    }, {
      delay: 0.2,
      duration: 3.5,
      opacity: 1,
    })
  }, []);

  if (!title || !subtitle) return null
  return (

    <header className='header'>
      <div className="title-container">
        <h1 className='title'>
          {title}
        </h1>
      </div>

      <h2 className='subtitle'>
        "{subtitle}"
      </h2>

    </header>
  )
}

export default memo(Title);
