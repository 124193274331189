import { memo, useEffect } from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Link } from 'react-router-dom';
import { gsap, ScrollTrigger } from 'gsap/all';
import './index.scss';
import SkillTag from './SkillTag';


const AboutMe = ({ datas, loading, error }) => {
  const titleaboutme = datas?.attributes?.titleaboutme;
  const titleSkills = datas?.attributes?.titleSkills;
  const aboutme = datas?.attributes?.aboutme;
  const skilltags = datas?.attributes?.skillTag;
  const aboutCtaText = datas?.attributes?.aboutCtaText;
  useEffect(() => {
    if (!loading && !error) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.set(".about-subtitle", { y: 50, opacity: 0 });
      gsap.to(".about-subtitle", {
        scrollTrigger: {
          trigger: ".about-subtitle",
          start: "top center"
        },
        opacity: 1,
        y: 0,
        delay: 0.3,
        duration: 1.3,
        ease: "power1.out",
      });
      gsap.set(".skill-subtitle", { y: 50, opacity: 0 });
      gsap.to(".skill-subtitle", {
        scrollTrigger: {
          trigger: ".skill-subtitle",
          start: "top center",
        },
        opacity: 1,
        y: 0,
        delay: 0.3,
        duration: 1.3,
        ease: "power1.out",
      });
      gsap.set(".about-text", { y: 70, opacity: 0 });
      gsap.to(".about-text", {
        scrollTrigger: {
          trigger: ".about-text",
          start: "top center",
        },
        opacity: 1,
        y: 0,
        duration: 1.3,
        ease: "power1.out",
      });
      gsap.set(".tags-container", { y: 70, opacity: 0 });
      gsap.to(".tags-container", {
        scrollTrigger: {
          trigger: ".tags-container",
          start: "top center",
        },
        opacity: 1,
        y: 0,
        duration: 1.3,
        ease: "power1.out",
      });
      gsap.set(".about-button", { y: 70, opacity: 0 });
      gsap.to(".about-button", {
        scrollTrigger: {
          trigger: ".about-button",
          start: "top center",
        },
        opacity: 1,
        y: 0,
        duration: 1.3,
        ease: "power1.out",
      });
    }
  }, [loading, error]);

  if ((loading || error) || !aboutCtaText || !titleaboutme || !aboutme || !skilltags) return null

  return (
    <section className='about-section'>
      <article className='about-article'>
        <h3 className='about-subtitle'>{titleaboutme}</h3>
        <div className='about-text'>
          {<BlocksRenderer content={aboutme} />}
        </div>
        <Link to='/contact' className='about-button'>
          <p>{aboutCtaText.text}</p>
        </Link>
      </article>

      <aside className='about-skills'>
        <h3 className='skill-subtitle'>{titleSkills}</h3>
        <div className='tags-container'>
          {skilltags.map((skill, index) => {
            return <SkillTag key={index} skillName={skill.name} />;
          })}
        </div>
      </aside>
    </section>

  )
}

export default memo(AboutMe);
