const privacyPolicy = {
  title: "Politique de Confidentialité",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Chez ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'entreprise'}, nous nous engageons à protéger la confidentialité de vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web, notre formulaire de contact, et lors du téléchargement de mon CV.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Collecte des informations",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `Lorsque vous utilisez notre formulaire de contact ou téléchargez mon CV, nous collectons les informations suivantes via le formulaire de contact :`
              },
              {
                type: "text",
                text: `- Votre adresse e-mail`
              },
              {
                type: "text",
                text: `- Le sujet de votre message et le contenu de votre message`
              },
              {
                type: "text",
                text: `- Aucune information personnelle supplémentaire n'est collectée lors du téléchargement du CV, toutefois, le CV téléchargé contient mes informations personnelles, telles que mon nom, mon adresse e-mail et mon numéro de téléphone.`
              },
              {
                type: "text",
                text: `Ces informations sont nécessaires pour que vous puissiez consulter mon profil professionnel dans le cadre d'une proposition d'emploi ou de collaboration.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "2. Utilisation des informations",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `Les informations collectées via notre formulaire de contact sont utilisées uniquement à des fins de communication professionnelle. Plus précisément :`
              },
              {
                type: "text",
                text: `- Pour répondre à vos demandes envoyées via le formulaire de contact.`
              },
              {
                type: "text",
                text: `- Le CV téléchargé est destiné à être utilisé uniquement dans le cadre d'une proposition d'emploi ou de collaboration. Toute autre utilisation de mes informations personnelles contenues dans le CV est strictement interdite.`
              },
              {
                type: "text",
                text: `Nous utilisons le service Email.js pour transférer les messages via le formulaire de contact à notre adresse email (${process.env.REACT_APP_CONTACT_EMAIL || 'tonemail@example.com'}), sans stockage à long terme des données.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "5. Droits de l'utilisateur",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `Conformément au Règlement Général sur la Protection des Données (RGPD), vous avez le droit de :`
              },
              {
                type: "text",
                text: `- Accéder aux informations personnelles que nous détenons à votre sujet, via le formulaire de contact uniquement.`
              },
              {
                type: "text",
                text: `- Demander la suppression de vos informations envoyées via le formulaire de contact.`
              },
              {
                type: "text",
                text: `Veuillez noter que le CV téléchargé contient mes informations personnelles et qu'aucun droit de modification ou suppression ne s'applique sur celles-ci, étant données qu'elles vous sont fournies uniquement pour consultation.`
              },
              {
                type: "text",
                text: `Pour exercer vos droits relatifs aux informations envoyées via le formulaire de contact, vous pouvez nous contacter à ${process.env.REACT_APP_CONTACT_EMAIL || 'tonemail@example.com'}.`
              }
            ]
          }
        ]
      }
    }
  ]
};

export default privacyPolicy;