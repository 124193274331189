const DSLMentions = {
  title: "Mentions Légales",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Mentions légales de l'application ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Éditeur",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `L'application ${process.env.REACT_APP_APP_NAME || 'Daily Shop List'} est éditée par ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'}, située à ${process.env.REACT_APP_COMPANY_ADDRESS || 'Adresse de l\'éditeur'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "2. Propriété Intellectuelle",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Tous les éléments de l'application, y compris les textes, logos et icônes, sont protégés par les lois sur la propriété intellectuelle et sont la propriété exclusive de ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'}, sauf indication contraire. Les icônes de cette application proviennent de '@expo/vector-icons' et de Flaticon (https://www.flaticon.com), regroupant des bibliothèques d'icônes open-source. Le logo de l'application a été généré à l'aide des modèles ChatGPT et DALL-E d'OpenAI.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "3. Licence des Polices",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `L'application utilise la police 'Quicksand', qui est sous licence SIL Open Font License, Version 1.1. Vous pouvez consulter les détails de cette licence sur https://scripts.sil.org/OFL.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "4. Responsabilité",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'} ne peut être tenue responsable des dommages directs ou indirects résultant de l'utilisation de l'application. L'application est fournie 'en l'état' sans aucune garantie d'aucune sorte.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "5. Contact",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Pour toute question concernant ces mentions légales, veuillez contacter ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'} à l'adresse suivante : ${process.env.REACT_APP_CONTACT_EMAIL || 'Adresse de contact'}.`
            }
          ]
        }
      ]
    }
  ]
};

export default DSLMentions;