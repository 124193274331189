const privacyPolicy = {
  title: "Privacy Policy",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `At ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}, we are committed to protecting your personal information. This privacy policy explains how we collect, use, and safeguard the information you provide when you use our website, contact form, and when downloading my CV.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Information Collection",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `When you use our contact form or download my CV, we collect the following information via the contact form:`
              },
              {
                type: "text",
                text: `- Your email address`
              },
              {
                type: "text",
                text: `- The subject and content of your message`
              },
              {
                type: "text",
                text: `- No additional personal information is collected when downloading the CV, however, the downloaded CV contains my personal information such as my name, email address, and phone number.`
              },
              {
                type: "text",
                text: `This information is necessary so that you can review my professional profile as part of a job offer or collaboration proposal.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "2. Use of Information",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `The information collected via our contact form is used solely for professional communication purposes. Specifically:`
              },
              {
                type: "text",
                text: `- To respond to your inquiries sent through the contact form.`
              },
              {
                type: "text",
                text: `- The downloaded CV is intended to be used only in the context of a job offer or collaboration. Any other use of my personal information contained in the CV is strictly prohibited.`
              },
              {
                type: "text",
                text: `We use the Email.js service to transfer messages sent via the contact form to our email address (${process.env.REACT_APP_CONTACT_EMAIL || 'yourmail@example.com'}), without long-term data storage.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "5. User Rights",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `In accordance with the General Data Protection Regulation (GDPR), you have the right to:`
              },
              {
                type: "text",
                text: `- Access the personal information we hold about you, through the contact form only.`
              },
              {
                type: "text",
                text: `- Request the deletion of the information you sent via the contact form.`
              },
              {
                type: "text",
                text: `Please note that the CV contains my personal information, and no right to modify or delete this information applies as it is provided to you solely for consultation purposes.`
              },
              {
                type: "text",
                text: `To exercise your rights regarding the information sent via the contact form, you can contact us at ${process.env.REACT_APP_CONTACT_EMAIL || 'yourmail@example.com'}.`
              }
            ]
          }
        ]
      }
    }
  ]
};

export default privacyPolicy;