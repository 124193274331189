const DSLNoticeEN = {
  title: "Terms of Use",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Terms of use for the ${process.env.REACT_APP_APP_NAME || 'Application'}, updated on ${process.env.REACT_APP_LAST_NOTICE_UPDATE || 'Date'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Acceptance of Terms",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `By using the ${process.env.REACT_APP_APP_NAME || 'Application'}, you agree to these terms of use in full. If you do not agree to these terms, you must not use the application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "2. Application Use",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The ${process.env.REACT_APP_APP_NAME || 'Application'} allows you to manage a shopping list. You agree to use the application for personal and non-commercial purposes only. Any attempt to decompile, disassemble, or reverse engineer the application is strictly prohibited.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "3. User Responsibility",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `You are responsible for protecting your personal information and securing access to your device. ${process.env.REACT_APP_COMPANY_NAME || 'The publisher'} cannot be held responsible for any data loss resulting from misuse of the application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "4. Modification of Terms",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_COMPANY_NAME || 'The publisher'} reserves the right to modify these terms of use at any time. Changes will take effect immediately after being published in the application. It is your responsibility to regularly review the terms of use.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "5. Applicable Law",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `These terms of use are governed by the laws of ${process.env.REACT_APP_COUNTRY || 'Country'}. Any disputes related to the use of the application will be subject to the exclusive jurisdiction of the courts of ${process.env.REACT_APP_CITY || 'City'}/${process.env.REACT_APP_COUNTRY || 'Country'}.`
            }
          ]
        }
      ]
    }
  ]
};

export default DSLNoticeEN;